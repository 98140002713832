import React, { useState } from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { Employee, SanitySimplePage } from "@utils/globalTypes"
import SEO from "@components/common/SEO"
import { BaseBlockContent } from "@utils/richText"
import * as s from "./SimplePage.module.scss"
import EmployeeDetail from "@components/employees/EmployeeDetail"
import EmployeesContainer from "@components/employees/EmployeesContainer"

type Props = {
  data: {
    sanitySimplePage: SanitySimplePage
  }
  location: Location
}

const SimplePage: React.FC<Props> = ({
  data: { sanitySimplePage },
  location,
}) => {
  const metadata = {
    location: location.href,
  }
  const { title, _rawContent, employees } = sanitySimplePage
  const [employee, setEmployee] = useState<Employee>(null)

  const [show, setShow] = useState<boolean>(false)

  const showEmployeeInfo = (employee: Employee) => {
    setEmployee(employee)
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
    setEmployee(null)
  }

  return (
    <>
      <SEO {...metadata} />
      <div>
        <Container className={s.container}>
          <Row>
            <Col xs={12}>
              <h1 className={s.title}>{title}</h1>
            </Col>
            <Col xs={12}>
              <BaseBlockContent blocks={_rawContent} className={s.blocks} />
            </Col>
          </Row>
          {employees?.length > 0 &&
            employees.map((section) => (
              <EmployeesContainer
                title={section.title}
                employees={section.employees}
                onClick={(employee) => showEmployeeInfo(employee)}
              />
            ))}
          {!!employee && (
            <EmployeeDetail
              employee={employee}
              show={show}
              onHide={handleClose}
            />
          )}
        </Container>
      </div>
    </>
  )
}

export default SimplePage

export const query = graphql`
  query sanitySimplePage($handle: String) {
    sanitySimplePage(handle: { current: { eq: $handle } }) {
      title
      _rawContent(resolveReferences: { maxDepth: 10 })
      employees {
        title
        employees {
          category
          name
          order
          _rawBio(resolveReferences: { maxDepth: 10 })
          position
          slug {
            current
          }
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
